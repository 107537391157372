import React, { FC, useRef } from "react";
import { BackgroundBlockAbs } from "../components/backgroundblockabs";
import { fontStacks, colors, borders } from "../global-style";
import { graphql, Link } from "gatsby";
import { InvestmentQuery } from "../../graphql-types";
import ReactResizeDetector from "react-resize-detector";
import { WpHtmlContent } from "../components/wp-html-content";

export const query = graphql`
  query Investment($id: ID!) {
    wp {
      investment(id: $id) {
        title
        investmentCustomFields {
          intro {
            content
            button {
              target
              title
              url
            }
          }
          description {
            aboutInvestment
            country
            sector
            since
            subtitle
          }
        }
      }
    }
  }
`;

const Investment: FC<{ data: InvestmentQuery }> = props => {
  const leftElement = useRef(null);
  const rightElement = useRef(null);

  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const leftElementRight = leftElement.current
          ? leftElement.current.getBoundingClientRect().right
          : 0;

        const leftX = leftElement.current
          ? leftElement.current.getBoundingClientRect().x
          : 0;

        const rightX = rightElement.current
          ? rightElement.current.getBoundingClientRect().x
          : 0;

        const isWrapped = rightX === leftX;

        const leftElementRightPx = leftElementRight + "px";

        return (
          <>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "12rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flexBasis: "83rem",
                  paddingTop: "1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    margin: "0 1.5rem",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "2rem",
                      lineHeight: "2.75rem",
                      display: "flex",
                      overflow: "hidden",
                      width: "100%",
                      border: borders.green,
                    }}
                  >
                    <div
                      style={{
                        fontFamily: fontStacks.merriweather,
                        fontSize: "2rem",
                        margin: "0.5rem",
                        flexBasis: "40rem",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.data.wp.investment?.title as string,
                        }}
                      />
                      <div
                        style={{
                          fontSize: "1rem",
                          fontFamily: fontStacks.sourceSansPro,
                          opacity: 0.77,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: props.data.wp.investment
                            ?.investmentCustomFields?.description
                            ?.subtitle as string,
                        }}
                      />
                    </div>
                    <div style={{ flexBasis: "40rem", margin: "0.5rem" }}></div>
                  </div>
                </div>
                <div
                  style={{
                    zIndex: 5,
                    display: "flex",
                    justifyContent: isWrapped ? "center" : "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    ref={leftElement}
                    style={{
                      flexBasis: "40rem",
                      overflow: "hidden",
                      marginBottom: isWrapped ? "2rem" : "0rem",
                    }}
                  >
                    <BackgroundBlockAbs
                      contentText={
                        props.data.wp.investment?.investmentCustomFields?.intro
                          ?.content as string
                      }
                      linkText={
                        props.data.wp.investment?.investmentCustomFields?.intro
                          ?.button?.title
                      }
                      linkUrl={
                        props.data.wp.investment?.investmentCustomFields?.intro
                          ?.button?.url
                      }
                      linkTarget={
                        props.data.wp.investment?.investmentCustomFields?.intro
                          ?.button?.target
                      }
                      maxWidth={`calc(${leftElementRightPx} + 1.5rem)`}
                    />
                  </div>
                  <div
                    ref={rightElement}
                    style={{
                      display: "flex",
                      flexBasis: "40rem",
                      border: borders.green,
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "30rem",
                        margin: "0 1.5rem",
                        padding: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>land: </div>
                        <div
                          style={{ marginLeft: "0.5rem" }}
                          dangerouslySetInnerHTML={{
                            __html: props.data.wp.investment
                              ?.investmentCustomFields?.description
                              ?.country as string,
                          }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>sector: </div>
                        <div
                          style={{ marginLeft: "0.5rem" }}
                          dangerouslySetInnerHTML={{
                            __html: props.data.wp.investment
                              ?.investmentCustomFields?.description
                              ?.sector as string,
                          }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>sinds:</div>
                        <div style={{ marginLeft: "0.5rem" }}>
                          <WpHtmlContent
                            htmlContent={
                              props.data.wp.investment?.investmentCustomFields
                                ?.description?.since as string
                            }
                          />
                        </div>
                      </div>

                      <WpHtmlContent
                        htmlContent={
                          props.data.wp.investment?.investmentCustomFields
                            ?.description?.aboutInvestment as string
                        }
                        style={{ marginBottom: "5rem" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};

export default Investment;
